import { Component, EventEmitter, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { WrapperComponent } from 'client/src/shared/ui/wrapper/wrapper.component'
import { FlexComponent } from 'client/src/shared/ui/flex/flex.component'
import { ButtonComponent } from 'client/src/shared/ui/button/button.component'
import { RouterModule } from '@angular/router'

@Component({
  selector: 'app-cookie-consent',
  standalone: true,
  imports: [CommonModule, WrapperComponent, FlexComponent, ButtonComponent, RouterModule],
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
  providers: []
})
export class CookieConsentComponent {
  private isConsented: boolean = false;
  private COOKIE_CONSENT = "ewaproduct-cookie-consent";
  private COOKIE_CONSENT_EXPIRE_DAYS = 100;

  constructor() {
      this.isConsented = this.getCookie(this.COOKIE_CONSENT) === '1';
  }

  private getCookie(name: string) {
      let ca: Array<string> = document.cookie.split(';');
      let caLen: number = ca.length;
      let cookieName = `${name}=`;
      let c: string;
      for (let i: number = 0; i < caLen; i += 1) {
          c = ca[i].replace(/^\s+/g, '');
          if (c.indexOf(cookieName) == 0) {
              return c.substring(cookieName.length, c.length);
          }
      }
      return '';
  }

  private deleteCookie(name: string) {
      this.setCookie(name, '', -1);
  }

  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
      let d:Date = new Date();
      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
      let expires:string = `expires=${d.toUTCString()}`;
      let cpath:string = path ? `; path=${path}` : '';
      document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  public getIsConsented() {
    return this.isConsented;
  }

  public consent() {
    if (!this.isConsented) {
      this.setCookie(this.COOKIE_CONSENT, '1', this.COOKIE_CONSENT_EXPIRE_DAYS);
      this.isConsented = true;
      //e.preventDefault();
    }

    return this.isConsented;
  }
}
