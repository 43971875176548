/**
 * Ewa Product service
 * Ewa
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Уровень квалификации партнера
 */
export type Grade = 'GradeClient' | 'GradePassivePartner' | 'GradeActivePartner' | 'GradeBronze' | 'GradeBronzePro' | 'GradeSilver' | 'GradeSilverPro' | 'GradeGold' | 'GradeGoldPro' | 'GradePlatinum' | 'GradePlatinumPro' | 'GradeDiamond';

export const Grade = {
    GradeClient: 'GradeClient' as Grade,
    GradePassivePartner: 'GradePassivePartner' as Grade,
    GradeActivePartner: 'GradeActivePartner' as Grade,
    GradeBronze: 'GradeBronze' as Grade,
    GradeBronzePro: 'GradeBronzePro' as Grade,
    GradeSilver: 'GradeSilver' as Grade,
    GradeSilverPro: 'GradeSilverPro' as Grade,
    GradeGold: 'GradeGold' as Grade,
    GradeGoldPro: 'GradeGoldPro' as Grade,
    GradePlatinum: 'GradePlatinum' as Grade,
    GradePlatinumPro: 'GradePlatinumPro' as Grade,
    GradeDiamond: 'GradeDiamond' as Grade
};

