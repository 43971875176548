<div class="cookie-consent-wrapper" *ngIf="!getIsConsented()">
    <app-wrapper>
        <app-flex fd="row" ai="center" jc="space-between">
            <app-flex gap="5px" fd="column" ai="flex-start" jc="flex-start">
                <p class="cookies-consent-title">Этот сайт использует cookie!</p>
                <p class="cookies-consent-messge">Продолжая просмотривать сайт, вы соглашаетесь на использования нами файлов cookie. Узнайте больше о <a routerLink="/procedures/consent-personal-data-agreement" class="underline">политике компании в области персональных данных</a>.</p>
            </app-flex>
            <button app-button color="white" variant="filled" (click)="consent()">ОК</button>
        </app-flex>
    </app-wrapper>
</div>