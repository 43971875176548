import { Injectable } from '@angular/core'
import { StocksResponse } from 'common/models/stocks-response'
import { RequestService } from 'common/services/request.service'
import { BehaviorSubject, tap } from 'rxjs'
import { Stock } from '../../../../../common/models/stock'

@Injectable({ providedIn: 'root' })
export class StockSelectorService {
	countryStocks$ = new BehaviorSubject<Stock[]>([])
	constructor(private requestService: RequestService) {}

	getStocks(countryId: string) {
		return this.requestService.post<unknown, StocksResponse>('/api/stock/get?country_id=' + countryId, {}).pipe(
			tap(data => {
				if (data.stocks) {
					this.countryStocks$.next(data.stocks)
				}
			})
		)
	}
}
