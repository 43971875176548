import { createReducer, on } from '@ngrx/store'
import { getStocks } from './stock.actions'
import { StocksResponse } from 'common/models/stocks-response'

export const featureKey = 'stocks'

export interface State {
  stocks: StocksResponse | null
  stocksLoading: boolean
}

export const initialState: State = {
  stocks: null,
  stocksLoading: false,
}

export const reducer = createReducer(
  initialState,
  on(getStocks.start, state => ({ ...state, stocksLoading: true })),
  on(getStocks.success, (state, { response }) => ({ ...state, stocksLoading: false, stocks: response })),
  on(getStocks.error, state => ({ ...state, stocksLoading: false })),
)
