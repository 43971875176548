import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

interface IMetaTag {
  name: string,
  content: string
}

interface IMetaProperty {
  property: string,
  content: string
}

interface ISeoModel {
  title: string,
  metaTags: IMetaTag[],
  metaProps: IMetaProperty[]
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private titleService: Title, private metaService: Meta) {}

  updateTitle(title: string) {
    this.titleService.setTitle(title);
  }

  updateMetaTags(tags: { name: string, content: string }[]) {
    tags.forEach(tag => this.metaService.updateTag(tag));
  }

  updateMetaProperty(props: { property: string, content: string }[]) {
    props.forEach(prop => this.metaService.updateTag(prop));
  }

  updateSeo(data: ISeoModel) {
    if (data.title) {
      this.updateTitle(data.title)
    } else {
      this.updateTitle("EWA PRODUCT - официальный сайт")
    }

    if (data.metaTags) {
      this.updateMetaTags(data.metaTags)
    } else {
      this.updateMetaTags([
        { name: "description", content: "Молодая и амбициозная компания с инновационной системой вознаграждения своих партнеров." }
      ])
    }

    if (data.metaProps) {
      this.updateMetaProperty(data.metaProps)
    } else {
      this.updateMetaProperty([
        { property: "og:title", content: "EWA PRODUCT - официальный сайт" },
        { property: "og:description", content: "Молодая и амбициозная компания с инновационной системой вознаграждения своих партнеров." },
        { property: "og:url", content: "https://ewaproduct.com" }
      ])
    }
  }
}
