import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { ButtonComponent } from '../button/button.component'
import { CommonModule } from '@angular/common'

@Component({
  standalone: true,
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ButtonComponent],
})
export class TabsComponent {
  @Input() options: readonly string[] | undefined | null = []
  //** This option will emit null on change */
  @Input() defaultOption?: string

  @Input() selectedOption!: string | null
  @Output() selectedOptionChange = new EventEmitter<string | null>()

  @Input() justify: 'start' | 'center' | 'end' = 'center'

  get isJustifyStart(): boolean {
    return this.justify === 'start'
  }

  get isJustifyEnd(): boolean {
    return this.justify === 'end'
  }

  public selectOption(option: string | null) {
    this.selectedOption = option
    this.selectedOptionChange.emit(this.selectedOption)
  }
}
