import { ChangeDetectionStrategy, Component } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Metrika } from 'ng-yandex-metrika'
import { environment } from '../environments/environment'
import { filter, map, mergeMap } from 'rxjs'
import { SeoService } from './services/seo.service'
import { AppState } from '../store/state'
import { Store } from '@ngrx/store'
import { MatDialog } from '@angular/material/dialog'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
	constructor(
		private metrika: Metrika,
		private router: Router,
		private seoService: SeoService,
		private store: Store<AppState>,
		private _dialog: MatDialog
	) {
		let prevPath = this.router.url

		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => this.router.routerState.root),
				map(route => {
					while (route.firstChild) route = route.firstChild
					return route
				}),
				filter(route => route.outlet === 'primary'),
				mergeMap(route => route.data)
			)
			.subscribe(data => {
				this.seoService.updateSeo({
					title: data['title'],
					metaTags: data['metaTags'],
					metaProps: data['metaProps']
				})

				if (environment.production) {
					const newPath = this.router.url
					if (newPath !== prevPath) {
						this.metrika.hit(newPath, { referer: prevPath })
						prevPath = newPath
					}
				}
			})
	}
}
