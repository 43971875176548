<button
  class="tab-button"
  [class.start]="isJustifyStart"
  [class.end]="isJustifyEnd"
  *ngIf="defaultOption"
  (click)="selectOption(null)"
  app-button
  [variant]="selectedOption === null ? 'filled' : 'outlined'"
  [color]="selectedOption === null ? 'dark' : 'white'"
  [pill]="true"
>
  {{ defaultOption }}
</button>

<button
  class="tab-button"
  [class.start]="isJustifyStart"
  [class.end]="isJustifyEnd"
  *ngFor="let option of options"
  (click)="selectOption(option)"
  app-button
  [variant]="option === selectedOption ? 'filled' : 'outlined'"
  [color]="option === selectedOption ? 'dark' : 'white'"
  [pill]="true"
>
  {{ option }}
</button>
