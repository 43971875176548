import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  constructor(private snackBar: MatSnackBar) {}

  success(msg: string) {
    this.snackBar.open(msg, undefined, {
      duration: 3000,
    })
  }

  error(msg: string) {
    this.snackBar.open(msg, 'Закрыть', {
      duration: 10_000,
    })
  }
}
