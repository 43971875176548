import { isDevMode, ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { reducers } from './state'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { EffectsModule } from '@ngrx/effects'
import { ShopEffects } from './shop/shop.effects'
import { HttpClientModule } from '@angular/common/http'
import { CartEffects } from './checkout/cart/cart.effects'
import { BoxberryEffects } from './checkout/delivery/delivery-office/boxberry/boxberry.effects'
import { KgPostEffects } from './checkout/delivery/delivery-office/kgpost/kgpost.effects'
import { FargoEffects } from './checkout/delivery/delivery-office/fargo/fargo.effects'
import { KazpostEffects } from './checkout/delivery/delivery-office/kazpost/kazpost.effects'
import { EuropostEffects } from './checkout/delivery/delivery-office/europost/europost.effects'
import { HaypostEffects } from './checkout/delivery/delivery-office/haypost/haypost.effects'
import { CdekEffects } from './checkout/delivery/delivery-office/cdek/cdek.effects'
import { DpdEffects } from './checkout/delivery/delivery-office/dpd/dpd.effects'
import { RupostEffects } from './checkout/delivery/delivery-office/rupost/rupost.effects'
import { DeliveryOfficeEffects } from './checkout/delivery/delivery-office/delivery-office.effects'
import { DeliveryIsCourierEffects } from './checkout/delivery/delivery-is-courier/delivery-is-courier.effects'
import { ErrorEffects } from './app/errors.effects'
import { PayEffects } from './checkout/pay/pay.effects'
import { OrdersEffects } from 'common/store/orders/orders.effects'
import { RegisterPersonEffects } from '../features/Registration/register-person'
import { TeamEffects } from '../widgets/account/team-table/model/team.effects'
import { CicEffects } from '../pages/account/cic-page/store/cic.effects'
import { SavedAddressesEffects } from '../features/saved-addresses/model/saved-addresses.effects'
import { AuthEffects as AuthFeatureEffects } from '../features/auth/model/auth.effects'
import { RegisterViaReferralEffects } from '../features/Registration/referral/model'
import { CountriesEffects } from '../features/geo-search/country-search/model/countries.effects'
import { GroupVolumeEffects } from '../widgets/account/group-volume-table/model/group-volume.effects'
import { PersonalVolumeEffects } from '../widgets/account/personal-volume-table/model/personal-volume.effects'
import { TransferHistoryEffects } from '../widgets/account/transfer-bonus/model/transfer-history.effects'
import { TransferBonusEffects } from '../widgets/account/transfer-bonus/model/transfer-bonus.effects'
import { StockEffects } from '../widgets/Stock/model'
import { ApplyTokenEffects } from '../features/checkout/apply-referrer-token/model/apply-token.effects'
import { LayoutEffects } from './app/layout.effects'
import { PartnerEffects } from '../pages/account/partner-page/model/partner.effects'
import { ConfigEffects } from '../app/model/config.effects'
import { TicketsTableEffects } from '../widgets/support/tickets-table/model/tickets-table.effects'
import { CreateTicketEffects } from '../pages/account/support-page/create-ticket-page/model/create-ticket.effects'
import { TicketEffects } from '../pages/account/support-page/ticket-page/model/ticket.effects'
import { TotalIncomeEffects } from '../pages/account/total-income-page/model/total-income.effects'
import { AuthEffects as AuthAppEffects } from './auth/auth.effects'
import { BonusInfoEffects } from '../widgets/checkout/bonus-info-after-order/model/bonus-info.effects'
import { FivePostEffects } from './checkout/delivery/delivery-office/five-post/five-post.effects'
import { BecomePartnerEffects } from '../features/account/become-partner/model/become-partner.effects'
import { TopEffects as TopGradesEffects } from '../widgets/top-grades-table/model/top.effects'
import { TopEffects as TopSallersEffects } from '../widgets/top-sallers-table/model/top.effects'
import { TopEffects as TopRecruitersEffects } from '../widgets/top-recruiters-table/model/top.effects'
import { TopEffects as TopClientServiceEffects } from '../widgets/top-client-service-table/model/top.effects'
import { ReviewListEffects } from '../widgets/review/model/review-list.effects'
import { RegionEffects } from '../features/geo-search/region-search/model/region.effects'
import { FamilyAgreementEffects } from '../widgets/account/family-agreement/model/family-agreement.effects'
import { NewsEffects } from '../widgets/news/model/news.effects'
import { ActiveEventsEffects } from '../widgets/events/active-events-list/model/events.effects'
import { ArchiveEventsEffects } from '../widgets/events/archive-events-list/model/events.effects'
import { EventItemEffects } from '../widgets/events/event-item/model/events.effects'
import { GeoSearchEffects } from 'common/store/geo-search/geo-search.effects'
import { ContactPageEffects } from '../widgets/geography/store/geography-page.effects'
import { NewPromoEffects } from '../pages/new-promo/store/new-promo.effects'
import { ThegameEffects } from '../pages/thegame-page/store/thegame.effects'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const storeDevTools: ModuleWithProviders<any>[] = []
if (isDevMode()) {
	storeDevTools.push(StoreDevtoolsModule.instrument())
}

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forRoot(reducers),
		HttpClientModule,
		EffectsModule.forRoot([]),
		EffectsModule.forFeature([
			LayoutEffects,
			ShopEffects,
			CartEffects,
			GeoSearchEffects,
			ErrorEffects,
			BoxberryEffects,
			KgPostEffects,
			FargoEffects,
			CdekEffects,
			EuropostEffects,
			HaypostEffects,
			KazpostEffects,
			DpdEffects,
			RupostEffects,
			DeliveryOfficeEffects,
			DeliveryIsCourierEffects,
			PayEffects,
			OrdersEffects,
			RegisterPersonEffects,
			TeamEffects,
			SavedAddressesEffects,
			AuthFeatureEffects,
			RegisterViaReferralEffects,
			CountriesEffects,
			GroupVolumeEffects,
			PersonalVolumeEffects,
			TransferHistoryEffects,
			TransferBonusEffects,
			StockEffects,
			ApplyTokenEffects,
			PartnerEffects,
			ConfigEffects,
			TicketsTableEffects,
			CreateTicketEffects,
			TicketEffects,
			TotalIncomeEffects,
			AuthAppEffects,
			BonusInfoEffects,
			FivePostEffects,
			BecomePartnerEffects,
			TopGradesEffects,
			TopSallersEffects,
			TopRecruitersEffects,
			TopClientServiceEffects,
			ReviewListEffects,
			RegionEffects,
			FamilyAgreementEffects,
			NewsEffects,
			ActiveEventsEffects,
			ArchiveEventsEffects,
			EventItemEffects,
			ContactPageEffects,
			CicEffects,
			NewPromoEffects,
			ThegameEffects
		]),
		...storeDevTools
	]
})
export class AppStoreModule {}
